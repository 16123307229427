/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.loginCard {
  border: 1px solid #ececec;
  background: linear-gradient(#f5f5f5, #f3f3f3);
  padding: 20px 30px 25px 30px;
  margin: 0 auto;
  margin-top: 50px;
}

.resetPasswordCard {
  border: 1px solid #ececec;
  background: linear-gradient(#f5f5f5, #f3f3f3);
  padding: 20px 30px 25px 30px;
  margin: 0 auto;
  margin-top: 50px;
}

.loginButton {
  margin-top: 15px;
  border-radius: 23px;
  font-size: 1.1em;
  padding-top: 4px;
}

.viewButton {
  border-radius: 23px;
  font-size: 1.2em;
  padding-top: 4px;
  width: 6rem;
}

.reportType {
  border: 1px solid lightgray;
  margin: 10px;
}

.favoriteimg {
  background-image: url(assets/favorites.png);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.recentimg {
  background-image: url(assets/recent.png);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.disabledExportButton {
  cursor: not-allowed !important;
  opacity: 0.3 !important;
}

.word {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/word1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .word:hover {
    border: 2px solid #379de1;
  }

.word1 {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/word1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .word1:hover {
    border: 2px solid #cdf0ff;
  }

.excel {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/excel1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .excel:hover {
    border: 2px solid #0dae47;
  }

.excel1 {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/excel1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .excel1:hover {
    border: 2px solid #c5ffda;
  }

.pdf {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/pdf1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .pdf:hover {
    border: 2px solid #e82a24;
  }

.pdf1 {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 27px;
  background-image: url(assets/pdf1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .pdf1:hover {
    border: 2px solid #ffe4e4;
  }

.text {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 26px 35px;
  background-image: url(assets/text1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .text:hover {
    border: 2px solid #a5a5a5;
  }

.text1 {
  display: inline-block;
  width: 37px;
  height: 46px;
  background-size: 26px 35px;
  background-image: url(assets/text1.png);
  background-repeat: no-repeat;
  border-radius: 3px;
  background-position: 3px 3px;
  border: 2px solid transparent;
  cursor: pointer;
  opacity: 0.8;
}

  .text1:hover {
    border: 2px solid #a5a5a5;
  }

.exportAs {
  display: inline-block;
  position: absolute;
  top: 7px;
  right: 170px;
  font-size: 21px;
  width: fit-content;
}

.reportViewHeader {
  width: 100%;
  left: 0;
  border-bottom: 1px solid gray;
  z-index: 100;
  background-color: #343a40;
  color: white;
  padding-left: 10px;
  line-height: 0.5;
}

.loadingReport {
  background-image: url(assets/loading.gif);
  background-repeat: no-repeat;
  height: 62px;
  padding-top: 65px;
  background-position: center;
  font-size: 20px;
  width: 100%;
  margin-top: 200px;
  text-align: center;
  z-index: 100000;
}

.loadingReports {
  background-image: url(assets/loading.gif);
  background-repeat: no-repeat;
  height: 41px;
  padding-top: 6px;
  background-position: left;
  font-size: 20px;
  width: 100%;
  text-align: left;
  z-index: 100;
  background-size: 2rem;
  padding-left: 35px;
  margin: -80px 0 50px 0;
}

.reportRenderError {
  background-color: #ffcf97;
  border: 1px solid #f9c180;
  border-radius: 3px;
  font-size: 20px;
  width: 100%;
  margin-top: 200px;
  text-align: center;
  z-index: 100000;
  padding: 10px;
}

.loadingFile {
  background-image: url(assets/loadFile.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  width: 100%;
  height: 100%;
  z-index: 100;
}

@media only screen and (min-width: 780px) {
  .loginCard {
    width: 50%;
  }

  .resetPasswordCard {
    width: 70%;
  }
}

.timeOutMessage {
  background-color: #defae1;
  border: 1px solid #adedb4;
  margin-top: 20px;
  padding: 20px;
  font-size: 21px;
  text-align: center;
  border-radius: 4px;
}

.passwordResetSent {
  background-size: 40px;
  background-image: url(assets/ok.png);
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 47px;
  font-size: 1.2em;
}

.btn:disabled {
  cursor: not-allowed;
}

.form-error {
  width: 100%;
  margin-top: 10px !important;
  font-size: 90%;
  color: #2e2929;
  background-color: #ffcacf;
  padding: 3px 3px 5px 5px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #f5c0c0;
  margin-top: 200px;
}

.generalErrors {
  color: #eb2020;
  font-size: 0.9em;
}


.spinner {
  margin-top: 10px;
  background-image: url(assets/loading.gif);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: top center;
  width: 100%;
  height: 20px;
}

.backToLogin {
  position: absolute;
  bottom: 5px;
  right: 7px;
  font-size: 0.9em;
}

.logo {
  background-image: url(assets/logo.png);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center left;
  padding-left: 29px;
}

.copyright {
  color: darkgray;
  text-align: right;
  font-size: 11px;
  position: absolute;
  right: 5px;
  top: 8px;
}

@media only screen and (max-device-width: 480px) {
  .copyright {
    max-width: 150px;
    top: 2px;
  }

  .reportName {
    font-size: 1em;
    max-width: 300px;
  }
}

.helpIcon {
  border-radius: 50%;
  border: 1px solid white;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 1.2em;
  padding: 0;
  padding-left: 3px;
  line-height: 1.1;
  margin: 8px 0 0 15px;
}

.lblOldSiteRetire {
  background-color: #ff5f5f;
  border-radius: 50%;
  display: inline-block;
  width: 28px;
  height: 28px;
  color: white;
  font-weight: 600;
  line-height: 1.6;
  border: 1px solid #fb5858;
}

.divOldSiteRetire {
  font-size: 16px;
  margin: 0 25% 10px 25%;
  padding: 7px 0 0 0;
  background-color: #ffd1a5;
  border: 1px solid #fbc88d;
  border-radius: 4px;
}

.divRecent {
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0 0 2px 5px;
}

  .divRecent:hover {
    border: 1px solid #dde0e4;
    background-color: #e9eef3;
  }

.selectedMenu {
  background-color: #79447f;
  border-radius: 8px;
  border: 1px solid #63435a;
  padding-top: 2px;
  margin-top: 5px;
  padding-bottom: 4px;
}

.sortable-item {
  margin-bottom: 2px;
  font-size: 1rem;
  line-height: 1.4em;
  cursor: grab;
  border-radius: 4px;
  border-color: #d4d4d4;
  padding: 3px 5px;
  border: 1px solid transparent;
}

  .sortable-item:hover {
    border: 1px solid #dde0e4;
    background-color: #e9eef3;
  }

.reportLink {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 3px 5px;
  width: 100%;
}

  .reportLink:hover {
    border: 1px solid #dde0e4;
    background-color: #e9eef3;
  }

.sortable-placeHolder {
  display: none;
  margin: 4px;
  font-size: 14px;
  line-height: 1.4em;
  cursor: grab;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #d4d4d4;
  background-color: white;
}

.card-body {
  padding: 1em !important;
}

.modal-header {
  background-color: #eaeaea;
  padding: 0.70rem 1rem;
}

.reportName {
  background-image: url(assets/report.png);
  background-repeat: no-repeat;
  padding-left: 30px;
}

.schedulePopupName {
  background-image: url(assets/schedule.png);
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position-y: 2px;
}

.stickyReportButtons {
  position: fixed;
  top: 0px;
  padding-top: 5px;
  z-index: 10;
  border: 1px solid #a8a8a8;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
}

.stickyScheduleButtons {
  position: sticky;
  top: -150px;
  margin-top:0 !important;
  z-index: 10;
}

#secondaryReportHeader {
  font-size: 1rem;
  display: none;
  font-weight: 600;
  width: 100%;
  margin: -5px 0 7px 0;
  text-align: center;
  background-color: #eaeaea;
  border-bottom: 1px solid #e4e4e4;
}

.helpH5 {
  color: #498dd0;
  font-size: 1.2rem;
}

.helpImg {
  margin: 1rem 0 2rem 0;
}

.helpLabelSectionHeader {
  color: #498dd0;
  display: inline;
}

.helpLabelSectionContent {
  display: inline;
}

.disabledLink {
  cursor:default !important;
  color: darkgray !important;
}

#btnDeleteShift.disabledLink:focus, #btnDeleteShift.disabledLink:active, #btnDeleteShift.disabledLink:hover,
#btnNewShift.disabledLink:focus, #btnNewShift.disabledLink:active, #btnNewShift.disabledLink:hover,
#btnClearPeople.disabledLink:focus, #btnClearPeople.disabledLink:active, #btnClearPeople.disabledLink:hover,
#btnSplitShift.disabledLink:focus, #btnSplitShift.disabledLink:active, #btnSplitShift.disabledLink:hover
{
  cursor:default !important;
  color: darkgray !important;
}

body {
  margin-bottom: 50px;
}

#divSchedules {
  border-radius: 4px;
  border: 1px solid lightgray;
  overflow: auto;
  position:relative;
  min-height:60px;
}

#schedules {
  width: 100%;
  border: 1px solid lightgray;
  border: none;
}

  #schedules th {
    background: #f0f0f0;
    font-weight: 400;
    padding: 5px;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: none;
    vertical-align: middle;
  }

    #schedules th:last-child {
      border-right: none;
    }

  #schedules tr:not(:first-child):hover {
    background-color: #f0f4fa;
  }

  #schedules tr.tblRowConflict:not(:first-child) {
    background-color: #ffdbd3;
  }
  #schedules tr.tblRowConflict.selectedRow:not(:first-child) {
    background-color: #ffc0b3;
  }
  #schedules tr.tblRowConflict:not(:first-child):hover {
    background-color: #ffeae6;
  }

  #schedules tr.tblRowGap:not(:first-child) {
    background-color: #fffcb8;
  }
  #schedules tr.tblRowGap.selectedRow:not(:first-child) {
    background-color: #f7f28b;
  }
  #schedules tr.tblRowGap:not(:first-child):hover {
    background-color: #fffdd7;
  }

  #schedules td {
    font-size: 0.85rem;
    padding: 0 5px;
    border:1px solid #eaeaea;
    cursor:default;
  }

.selectedRow {
  background-color: #cde0f7;
}

select {
  border: 1px solid lightgray;
  border-radius: 3px;
  height: 1.8rem;
}

select:focus{
  outline: 1px solid gray;
}

.deletedRowcount{
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 9px;
    border: 1px solid #b5b5b5;
    padding: 0px 6px;
    line-height: 1.4;
    border-radius: 50%;
    font-size: 0.85rem;
    background-color: #c3c3c3;
    color: white;
    cursor:default;
}

.sortArrow{
    color: #b9b9b9;
    margin: 0;
    font-size: 0.8rem;
}
